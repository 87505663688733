@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

.sap-transmit-widget {
  $z-index: tokens.$z-index-snackbar;

  position: fixed;
  z-index: $z-index;
  right: tokens.$space-small-x;
  bottom: tokens.$space-small-x;
  display: flex;
  width: functions.px-to-rem(350);
  gap: tokens.$space-small-x;
  flex-direction: column;
}
