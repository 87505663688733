@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Table Empty State
//

.c-table-empty-state {
  --table-border-bottom-width: 0;
  --table-col-padding: #{tokens.$space-large tokens.$space-small-2x};

  //
  // Elements
  // -------------------------

  &__content {
    text-align: center;
    vertical-align: middle;
  }

  //
  // Modifiers
  // -------------------------
}
