@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Note Editor Layout
//

.o-note-editor-layout {
  display: grid;
  grid-template-rows: auto 1fr auto auto;
  grid-template-columns: 1fr auto 1fr auto;
  gap: tokens.$space-small-x;

  @include mixins.media-breakpoint-up(lg) {
    grid-template-areas:
      'canvas canvas canvas third-toolbar'
      'canvas canvas canvas .'
      'canvas canvas canvas .'
      'first-toolbar second-toolbar . .';
  }

  @include mixins.media-breakpoint-down(md) {
    grid-template-areas:
      'canvas canvas canvas third-toolbar'
      'canvas canvas canvas .'
      'canvas canvas canvas first-toolbar'
      '. second-toolbar . .';
  }

  &__canvas-area {
    min-width: 0;
    grid-area: canvas;
  }

  &__first-toolbar-area {
    grid-area: first-toolbar;
    align-self: center;
  }

  &__second-toolbar-area {
    grid-area: second-toolbar;
    align-self: center;
  }

  &__third-toolbar-area {
    grid-area: third-toolbar;
  }
}
