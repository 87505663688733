@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

.row {
  display: flex;
  justify-content: space-between;
  padding: 0 tokens.$space-large-2x;
}

.box {
  display: flex;
  width: tokens.$space-small-4x;
  flex-direction: column;
  align-items: center;
}

.label {
  display: block;
}

.caption {
  display: block;
  margin-bottom: tokens.$space-small-4x;
}

/* stylelint-disable-next-line selector-class-pattern */
.radioError {
  svg {
    color: tokens.$color-error;
  }
}
