@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

.container {
  display: flex;
  overflow: visible;
  gap: tokens.$space-small-3x;
  flex-direction: column;
  align-items: center;
  padding-bottom: tokens.$space-small-3x;
}

.caption {
  font-size: functions.px-to-rem(10);
  font-weight: 700;
}
