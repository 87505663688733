@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Table
//

.c-table {
  $padding: tokens.$space-small-2x;

  width: 100%;
  max-width: 100%;
  border-collapse: collapse;

  //
  // Elements
  // -------------------------

  &__td,
  &__th {
    padding: var(--table-col-padding, #{$padding});
    border-bottom: var(--table-border-bottom-width, 1px) solid tokens.$color-border;
    background-color: var(--table-col-background-color, var(--table-col-background-color-sticky));

    &.is-bordered {
      border: 1px solid tokens.$color-border;
    }
  }

  &__th {
    vertical-align: bottom;

    &:focus {
      background-color: tokens.$color-gray-50;
      outline: 0;
    }
  }

  &__td {
    vertical-align: top;

    &:first-child {
      padding-left: calc((var(--table-row-depth, 0) * #{$padding}) + #{$padding});
    }
  }

  &__tr {
    &:last-child {
      .c-table__td {
        border-bottom: 0;
      }
    }

    &:focus {
      outline: functions.px-to-rem(1px) solid #{tokens.$color-gray-600};

      &:not(:focus-visible) {
        outline: none;
      }
    }

    &.is-selected {
      --table-col-background-color: var(
        --table-col-selected-background-color,
        #{tokens.$color-primary}
      );

      color: var(--table-col-selected-color, #{tokens.$color-white});
    }

    &.is-sticky {
      --table-col-background-color-sticky: #{tokens.$color-white};

      // 1. Fixes the border-bottom deformation of the `<th>` element
      //    that occurs when the element's position is sticky
      > * {
        position: sticky;
        top: 0;
        box-shadow: 0 1px 0 0 tokens.$color-border; // [1]
      }
    }
  }

  &__td-small {
    min-width: functions.px-to-rem(50);
  }

  //
  // Modifiers
  // -------------------------

  &--stripe {
    /* stylelint-disable-next-line selector-max-specificity */
    .c-table__tr:not(.is-selected):nth-of-type(odd) .c-table__td {
      --table-col-background-color: rgb(0 0 0 / 3%);
    }
  }
}
