@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Note Editor Canvas
//

.c-note-editor-canvas {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: tokens.$border-radius-medium;
  box-shadow:
    0 1px functions.px-to-rem(2) 0 rgb(60 64 67 / 30%),
    0 1px functions.px-to-rem(3) 1px rgb(60 64 67 / 15%);
}
