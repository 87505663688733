@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

.project-filter-form-actions {
  display: flex;
  gap: tokens.$space-small-3x;
  justify-content: flex-end;

  @include mixins.media-breakpoint-down('sm') {
    flex-direction: column-reverse;
  }

  @include mixins.media-breakpoint-up('md') {
    &__submit {
      flex-basis: functions.px-to-rem(300);
    }
  }
}
