@use 'sass:math';
@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Form Control
//

.c-form-control {
  $color-placeholder: tokens.$color-gray-500;
  $border-width: 1px;
  $border-radius-default: tokens.$border-radius-medium;
  $border-radius: var(--c-form-control-left-side-radius, $border-radius-default)
    var(--c-form-control-right-side-radius, $border-radius-default)
    var(--c-form-control-right-side-radius, $border-radius-default)
    var(--c-form-control-left-side-radius, $border-radius-default);
  $font-size: functions.px-to-rem(12);
  $line-height: functions.px-to-rem(24);
  $padding-vertical: functions.px-to-rem(11);
  $padding-horizontal: tokens.$space-small-2x;
  $height-base: ($line-height + ($padding-vertical * 2) + (functions.px-to-rem($border-width) * 2));
  $z-index: 0;
  $right-icon-size: functions.px-to-rem(24);
  $right-icon-gap-right: tokens.$space-small-2x;
  $right-icon-gap-left: tokens.$space-small-4x;

  position: relative;
  z-index: $z-index;
  border-radius: $border-radius;

  &__control {
    display: block;
    width: 100%;
    height: $height-base; // Make inputs at least the height of their button counterpart (base line-height + padding + border)
    padding: $padding-vertical $padding-horizontal;
    appearance: none;
    background-color: var(--c-form-control-control-bg-color, inherit);
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    border-color: var(--c-form-control-control-border-color, tokens.$color-border);
    border-radius: $border-radius;
    border-style: solid;
    border-width: $border-width;
    color: var(--c-form-control-control-color, tokens.$color-gray-800);
    font-family: Roboto, sans-serif;
    font-size: $font-size;
    line-height: $line-height;

    &::placeholder {
      color: $color-placeholder;
      opacity: 1;
    }

    &:disabled {
      --c-form-control-control-bg-color: #{tokens.$color-gray-200};
      --c-form-control-control-color: #{tokens.$color-black};
    }

    &::-webkit-contacts-auto-fill-button,
    &::-webkit-credentials-auto-fill-button {
      position: absolute;
      visibility: hidden;
    }

    //
    // Elements
    // -------------------------

    &--select {
      appearance: none;
    }

    &--textarea {
      overflow: auto; // Per CSS3 UI, `resize` only applies when `overflow` isn't `visible`
      height: auto;
      resize: none; // Options: horizontal, vertical, both
    }

    &--file {
      @include mixins.text-truncate;
    }
  }

  &__right-icon {
    position: absolute;
    top: math.div($height-base - $right-icon-size, 2);
    right: $right-icon-gap-right;
    color: var(--c-form-control-right-icon-color, tokens.$color-gray-500);
    font-size: $right-icon-size;
  }

  &__file-input {
    @include mixins.visually-hidden;
  }

  &__placeholder {
    color: $color-placeholder;
    user-select: none;
  }

  &.has-right-icon {
    .c-form-control__control {
      padding-right: $right-icon-gap-right + $right-icon-gap-left + $right-icon-size;
    }
  }

  &.is-focused {
    --c-form-control-control-border-color: #{tokens.$color-gray-600};
  }

  &.is-disabled {
    --c-form-control-control-bg-color: #{tokens.$color-gray-200};
    --c-form-control-control-color: #{tokens.$color-black};
  }

  // &.is-valid {
  //   --c-form-control-control-border-color: #{tokens.$color-success};
  //   --c-form-control-right-icon-color: #{tokens.$color-success};
  // }

  &.is-invalid {
    --c-form-control-control-border-color: #{tokens.$color-error};
    --c-form-control-right-icon-color: #{tokens.$color-error};
  }

  //
  // Modifiers
  // -------------------------
}
