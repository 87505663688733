@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Protective Equipment Checkboxes
//

.o-protective-equipment-checkboxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(functions.px-to-rem(136), 1fr));
  gap: tokens.$space-small-x;
  padding-top: tokens.$space-small-x;
  padding-bottom: tokens.$space-small-x;
}
