@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Note Editor Text Format Picker
//

.c-note-editor-text-format-picker {
  //
  // Elements
  // -------------------------

  // We applied this logic to increase CSS specificity order for top property.
  &__content.c-note-editor-text-format-picker__content {
    min-width: 0;
    padding-right: tokens.$space-small-3x;
  }

  &__actions {
    display: grid;
    grid-template-columns: auto;
    gap: tokens.$space-small-4x;
    padding: tokens.$space-small-4x;
    background-color: tokens.$color-white;
    border-radius: tokens.$border-radius-medium;
    box-shadow:
      0 1px functions.px-to-rem(3) rgb(60 64 67 / 30%),
      0 1px functions.px-to-rem(2) rgb(60 64 67 / 30%);
  }

  //
  // Modifiers
  // -------------------------
}
