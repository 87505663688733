@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

.c-project-status-select {
  --c-form-control-control-bg-color: #{tokens.$color-white};

  &.is-active-construction,
  &.is-ordered {
    --c-form-control-control-color: #{tokens.$color-success};
  }

  &.is-closed,
  &.is-canceled {
    --c-form-control-control-color: #{tokens.$color-muted};
  }
}
