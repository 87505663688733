@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

.o-document-log {
  width: 100%;
  min-height: functions.px-to-rem(80);
  max-height: functions.px-to-rem(400);
  overflow-y: auto;

  &__log {
    display: grid;
    width: 100%;
    grid-template-columns: auto auto;
    gap: tokens.$space-small-3x tokens.$space-small-x;
    justify-content: flex-start;
  }
}
