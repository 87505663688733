@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

//
// Risk Assessment Missing Signature Notification
//

.risk-assessment-missing-signature-notification {
  //
  // Elements
  // -------------------------

  &__list {
    color: tokens.$color-white;
    padding-inline-start: tokens.$space-medium;
  }

  //
  // Modifiers
  // -------------------------
}
