@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Card Checkbox
//

.c-card-checkbox {
  $z-index: 0;

  position: relative;
  z-index: $z-index;
  display: inline-flex;

  //
  // Elements
  // -------------------------
  &__mask {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: tokens.$space-small-x tokens.$space-small-3x tokens.$space-small-3x;
    background-color: tokens.$color-white;
    border-radius: tokens.$border-radius-medium;
    box-shadow:
      0 1px functions.px-to-rem(2) 0 rgb(60 64 67 / 30%),
      0 1px functions.px-to-rem(3) 1px rgb(60 64 67 / 15%);
  }

  &__checkmark {
    position: absolute;
    top: functions.px-to-rem(6);
    right: functions.px-to-rem(6);
    font-size: functions.px-to-rem(20);
    pointer-events: none;
  }

  &__input {
    @include mixins.visually-hidden;

    &:focus + .c-card-checkbox__mask {
      outline: functions.px-to-rem(2) dotted tokens.$color-gray-500;
      outline-offset: 1px;
    }

    &:checked + .c-card-checkbox__mask {
      background-color: tokens.$color-warning;
      color: tokens.$color-gray-900;
    }

    &:disabled + .c-card-checkbox__mask {
      background-color: tokens.$color-gray-300;
      box-shadow: none;
      color: tokens.$color-gray-700;
      opacity: 0.5;
    }

    &:not(:disabled) + .c-card-checkbox__mask {
      cursor: pointer;
    }

    // stylelint-disable selector-max-specificity
    &:not(:checked) + .c-card-checkbox__mask .c-card-checkbox__checkmark {
      display: none;
    }

    &.is-invalid + .c-card-checkbox__mask {
      border: functions.px-to-rem(3) solid tokens.$color-warning;
    }
  }

  &__label {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &__image {
    width: functions.px-to-rem(100);
    flex-shrink: 0;
    margin-bottom: tokens.$space-small-3x;
    user-select: none;
  }

  //
  // Modifiers
  // -------------------------
}
