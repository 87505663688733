@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

//
// DocumentDetailBreadcrumbs
//

.document-detail-breadcrumbs {
  //
  // Elements
  // -------------------------
  &__folder-breadcrumb {
    display: block;
    max-width: tokens.$size-big-2;
  }

  //
  // Modifiers
  // -------------------------
}
