@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Form Feedback
//

.c-form-feedback {
  display: flex;
  color: tokens.$color-gray-600;

  &__inner {
    max-width: functions.px-to-rem(170);
  }

  &__info-button {
    flex-shrink: 0;
    color: inherit;
  }

  //
  // Elements
  // -------------------------

  &--theme-invalid {
    color: tokens.$color-error;
  }

  &--theme-valid {
    color: tokens.$color-success;
  }

  //
  // Modifiers
  // -------------------------
}
