@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

//
// Sidebar Layout
//

.o-sidebar-layout {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  padding-top: tokens.$space-medium;
  padding-bottom: tokens.$space-medium;
}
