@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

.c-precaution-category-header {
  $icon-size: functions.px-to-rem(60);
  $small-icon-size: functions.px-to-rem(48);

  display: flex;
  align-items: center;
  padding-bottom: tokens.$space-small-3x;
  border-bottom: 1px solid tokens.$color-border;

  /* stylelint-disable-next-line selector-class-pattern */
  &__iconContainer {
    width: var(--precaution-category-header-icon-size, #{$icon-size});
    height: auto;
    flex-shrink: 0;
    margin-right: tokens.$space-small-x;
  }

  &--small {
    --precaution-category-header-icon-size: #{$small-icon-size};
  }
}
