@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Project Detail Menu
//

.o-project-detail-navigation {
  &__menu {
    @include mixins.list-unstyled($margin-bottom: 0);

    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(functions.px-to-rem(120), 1fr));
    gap: tokens.$space-small-x;
  }
}
