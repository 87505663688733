@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

.o-profit-centers-list {
  @include mixins.media-breakpoint-up(sm) {
    min-height: 100%;
    max-height: functions.px-to-rem(450);
    padding-left: tokens.$space-small-3x;
    border-left: 1px solid tokens.$color-gray-300;
    overflow-y: auto;
  }
}
