@use 'sass:math';
@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Card Button
//

.card-button {
  position: relative;
  z-index: 0;
  display: flex;
  overflow: hidden;
  height: functions.px-to-rem(180);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: tokens.$space-small-x;
  border: 0;
  background: var(--card-background-color, tokens.$color-white);
  border-radius: tokens.$border-radius-medium;
  box-shadow:
    0 1px functions.px-to-rem(3) rgb(60 64 67 / 30%),
    0 1px functions.px-to-rem(2) rgb(60 64 67 / 30%);

  &:hover {
    text-decoration: none;
  }

  &:disabled,
  &[aria-disabled='true'] {
    background-color: tokens.$color-gray-200;
    box-shadow: inset 0 0 0 1px tokens.$color-gray-400;
    filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  }

  &:not(:disabled, [aria-disabled='true']) {
    cursor: pointer;
  }

  //
  // Elements
  // -------------------------
  &__title {
    color: tokens.$color-black;
    text-align: center;
  }

  &__image {
    width: functions.px-to-rem(70);
    margin-top: tokens.$space-small-x;
    margin-bottom: tokens.$space-small-x;
  }

  &__corner-badge {
    $badge-width: functions.px-to-rem(86);
    $badge-height: functions.px-to-rem(40);

    // $border-radius-gap is the gap between the component's corner (without radius)
    // and the top center of the badge
    $border-radius-gap: functions.px-to-rem(3);

    position: absolute;
    top: 0;
    right: -(math.div($badge-width, 2));
    display: flex;
    width: $badge-width;
    height: $badge-height;
    align-items: flex-end;
    justify-content: center;
    padding: tokens.$space-small-3x;
    pointer-events: none;
    text-align: center;
    transform: rotate(45deg) translateY($border-radius-gap);
    transform-origin: top center;

    &--color-default {
      background-color: tokens.$color-blue-gray-800;
      color: tokens.$color-white;
    }

    &--color-primary {
      background-color: tokens.$color-primary;
      color: tokens.$color-white;
    }
  }

  &__status-badge {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    gap: tokens.$space-small-4x;
    align-items: center;
    padding: tokens.$space-small-4x;
    background-color: var(--status-badge-background-color);
    border-bottom-right-radius: tokens.$border-radius-small;
    box-shadow: 0 functions.px-to-rem(2) functions.px-to-rem(8) 0 rgb(22 22 22 / 15%);
    color: var(--status-badge-color);
    pointer-events: none;
  }

  &__status-icon-container {
    display: flex;
    width: tokens.$size-tiny-4;
    height: tokens.$size-tiny-4;
    align-items: center;
    justify-content: center;
    background-color: var(--status-badge-color);
    border-radius: tokens.$border-radius-circle;
    color: tokens.$color-white;
  }

  //
  // Modifiers
  // -------------------------
  &--status-success {
    --card-background-color: #{linear-gradient(
        to bottom,
        tokens.$color-white,
        tokens.$color-green-50
      )};
    --status-badge-background-color: #{tokens.$color-green-100};
    --status-badge-color: #{tokens.$color-green-600};
  }

  &--status-warning {
    --card-background-color: #{linear-gradient(
        to bottom,
        tokens.$color-white,
        tokens.$color-yellow-50
      )};
    --status-badge-background-color: #{tokens.$color-yellow-100};
    --status-badge-color: #{tokens.$color-yellow-600};
  }

  &--status-error {
    --card-background-color: #{linear-gradient(to bottom, tokens.$color-white, tokens.$color-red-50)};
    --status-badge-background-color: #{tokens.$color-red-50};
    --status-badge-color: #{tokens.$color-red-600};
  }
}
