@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Data List
//

.o-data-list {
  // Adding 1px padding and than streching it with negative margin
  // fixes the outline overflow problem for table rows on focus
  $gap: 1px;

  overflow: auto;
  padding-right: $gap;
  padding-left: $gap;
  margin-right: -$gap;
  margin-left: -$gap;

  // According to customer's request, we've forced the scroll bar
  // to always be visible in webkit browsers
  &::-webkit-scrollbar {
    // stylelint-disable-next-line property-no-vendor-prefix
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: functions.px-to-rem(11);
  }

  &::-webkit-scrollbar-thumb {
    border: functions.px-to-rem(2) solid tokens.$color-white; // should match background, can't be transparent
    background-color: rgba(tokens.$color-blue-gray-800, 0.75);
    border-radius: tokens.$border-radius-small;
    box-shadow: 0 0 1px rgb(255 255 255 / 50%);
  }

  //
  // Elements
  // -------------------------

  //
  // Modifiers
  // -------------------------

  &--normal {
    max-height: functions.px-to-rem(350);
  }

  &--small {
    max-height: functions.px-to-rem(250);
  }
}
