@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

//
// PDF Manipulator
//

.pdf-manipulator {
  overflow: hidden;
  aspect-ratio: 528 / 754;

  //
  // Elements
  // -------------------------

  &:global(.is-fullscreen) {
    position: fixed;
    width: 100%;
    height: 100%;
    inset: 0;
  }

  &__pdf-viewer {
    width: 100%;
    height: 100%;
    background-color: tokens.$color-gray-200;
  }

  &__floating-actions {
    position: absolute;
    right: tokens.$size-small-4;
    bottom: tokens.$size-small-2;
  }

  //
  // Modifiers
  // -------------------------
}
