@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

.label {
  font-weight: 700;
}

/* stylelint-disable-next-line selector-class-pattern */
.radioError {
  svg {
    color: tokens.$color-error;
  }
}
