@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Construction Note Editor Container
//

.o-construction-note-editor-container {
  // TODO: Fix temporary solution
  height: calc(100vh - #{functions.px-to-rem(185)});
}
