@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

.c-image-thumbnail {
  border-radius: tokens.$border-radius-medium;
  box-shadow:
    0 1px functions.px-to-rem(3) rgb(60 64 67 / 30%),
    0 1px functions.px-to-rem(2) rgb(60 64 67 / 30%);
  object-fit: cover;
}
