@use 'sass:math';
@use 'sass:color';
@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

.c-choice {
  $z-index: 0;
  $z-index-input: -1;
  $size: functions.px-to-rem(20);
  $radius-checkbox: functions.px-to-rem(3);
  $radius-radio: math.div($size, 2);
  $border-width: functions.px-to-rem(2);
  $checked-color: tokens.$color-primary;
  $background-color: null;
  $background-color-checked: null;
  $background-color-disabled: tokens.$color-gray-50;
  $border-color: #a1a1a1;
  $border-color-checked: tokens.$color-primary;
  $border-color-focused: #a1a1a1;
  $border-color-disabled: #e6e6e6;
  $checkmark-size-checkbox: functions.px-to-rem(14);
  $checkmark-size-radio: functions.px-to-rem(10);
  $checkmark-color: $checked-color;
  $checkmark-disabled: color.adjust(tokens.$color-white, $lightness: -25%);
  $label-font-size: functions.px-to-rem(16);
  $label-line-height: 1.5;
  $mask-margin-ends: functions.px-to-em(
    math.div(functions.rem-to-px(($label-font-size * $label-line-height) - $size), 2)
  );

  position: relative;
  z-index: $z-index;
  display: flex;

  //
  // Mask
  //

  &__mask {
    display: flex;
    width: $size;
    height: $size;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    border: $border-width solid $border-color;
    margin-top: $mask-margin-ends;
    margin-bottom: $mask-margin-ends;
    background-color: $background-color;
    color: $checkmark-color;
    cursor: pointer;

    &--checkbox {
      border-radius: $radius-checkbox;

      > .c-choice__checkmark {
        font-size: $checkmark-size-checkbox;
      }
    }

    &--radio {
      border-radius: $radius-radio;

      > .c-choice__checkmark {
        font-size: $checkmark-size-radio;
      }
    }
  }

  //
  // Elements
  // -------------------------

  &__input {
    position: absolute;
    z-index: $z-index-input;
    opacity: 0;

    // stylelint-disable selector-max-specificity
    &:not(:checked) + .c-choice__mask .c-choice__checkmark {
      display: none;
    }
  }

  &__label {
    margin-left: tokens.$space-small-3x;
  }

  &.is-disabled {
    .c-choice__mask {
      background-color: $background-color-disabled;
      border-color: $border-color-disabled;
      color: $checkmark-disabled;
    }

    .c-choice__label {
      color: $checkmark-disabled;
    }
  }

  &--primary {
    /* stylelint-disable-next-line no-descending-specificity */
    .c-choice__mask {
      background-color: $background-color;
      color: $checkmark-color;
    }

    .c-choice__input {
      /* stylelint-disable-next-line */
      &:checked + .c-choice__mask {
        background-color: $background-color-checked;
        border-color: $border-color-checked;
      }
      /* stylelint-disable-next-line */
      &:focus + .c-choice__mask {
        border-color: $border-color-focused;
      }
    }
  }

  &--secondary {
    .c-choice__input {
      /* stylelint-disable-next-line */
      &:checked + .c-choice__mask {
        background-color: tokens.$color-primary;
        border-color: tokens.$color-primary;
      }

      /* stylelint-disable-next-line */
      &:focus + .c-choice__mask {
        border-color: $border-color-focused;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .c-choice__mask {
      background-color: $background-color;
      color: tokens.$color-white;
    }
  }

  //
  // Modifiers
  // ------------------------
}
