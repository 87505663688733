@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Note Editor Color Picker
//

.c-note-editor-color-picker {
  $swatch-size: functions.px-to-rem(24);
  $preview-size: functions.px-to-rem(56);

  //
  // Elements
  // -------------------------

  // We applied this logic to increase CSS specificity order for top property.
  &__panel.c-note-editor-color-picker__panel {
    min-width: 0;
    padding-right: tokens.$space-small-3x;
  }

  &__picker {
    display: grid;
    grid-auto-flow: row;
    gap: tokens.$space-small-x;
  }

  &__preview {
    width: $preview-size;
    height: $preview-size;
    border: 1px solid rgb(0 0 0 / 4%);
    border-radius: tokens.$border-radius-large;
  }

  &__swatches {
    display: grid;
    grid-template-columns: repeat(2, $swatch-size);
    gap: tokens.$space-small-3x;
  }

  &__swatch {
    display: flex;
    width: $swatch-size;
    height: $swatch-size;
    padding: tokens.$space-small-5x;
    border: functions.px-to-rem(2) solid;
    background-color: currentcolor;
    border-radius: 50%;
    box-shadow: 0 0 0 1px rgb(0 0 0 / 4%);
    cursor: pointer;

    &::after {
      display: block;
      width: 100%;
      height: 100%;
      background-color: currentcolor;
      border-radius: 50%;
      content: '';
    }

    &.is-selected {
      background-color: rgb(0 0 0 / 4%);
    }
  }

  //
  // Modifiers
  // -------------------------
}
