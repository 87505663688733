@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/functions';

//
// Field Layout
//

.c-field-layout {
  //
  // Elements
  // -------------------------

  &__label {
    display: block;
    margin-bottom: functions.px-to-rem(6);
  }

  &__feedback {
    margin-top: tokens.$space-small-4x;
  }

  //
  // Modifiers
  // -------------------------
}
