@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;

//
// Row Heading
//

.c-row-heading {
  --table-col-background-color: #{tokens.$color-gray-300};

  &__th {
    font-weight: 400;
  }
}
