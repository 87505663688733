@use '@hs-baumappe/legacy-design-tokens/scss' as tokens;
@use '@hs-baumappe/styles/scss/abstracts/mixins';

//
// Note Editor Toolbar
//

.c-note-editor-toolbar {
  display: flex;
  align-items: center;

  > *:not(:first-child) {
    margin-top: var(--c-note-editor-toolbar-item-margin-top);
    margin-bottom: var(--c-note-editor-toolbar-item-margin-bottom);
    margin-left: var(--c-note-editor-toolbar-item-margin-left, tokens.$space-small-x);
  }

  &--direction-column {
    --c-note-editor-toolbar-item-margin-top: #{tokens.$space-small-x};
    --c-note-editor-toolbar-item-margin-left: 0;

    flex-direction: column;
  }

  // stylelint-disable-next-line selector-class-pattern
  &--direction-column\@md-down {
    @include mixins.media-breakpoint-down(md) {
      --c-note-editor-toolbar-item-margin-top: #{tokens.$space-small-x};
      --c-note-editor-toolbar-item-margin-left: 0;

      flex-direction: column;
    }
  }

  // stylelint-disable-next-line selector-class-pattern
  &--direction-column-reverse\@md-down {
    @include mixins.media-breakpoint-down(md) {
      --c-note-editor-toolbar-item-margin-bottom: #{tokens.$space-small-x};
      --c-note-editor-toolbar-item-margin-left: 0;

      flex-direction: column-reverse;
    }
  }
}
